table {
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-weight: 100;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    background-color: #FBFCFC;
}

th,
td {
    padding: 15px;
    background-color: #ffffff33;
    color: black;
}

th {
    text-align: left;
}

thead th {
    background-color: #20419a;
    color: #fff;
}

tbody tr:hover {
    background-color: #00000033;
}

tbody td {
    position: relative;
}

tbody td:hover:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -9999px;
    bottom: -9999px;
    background-color: #00000033;
    z-index: -1;
}