@media (max-width: 1050px) {
  .branch-resume-col {
    width: calc(100% - 10px);
    display: block;
  }
  .modal-container {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: calc(100% - 10px);
    display: block;
  }
  .branch-resume-table-view {
    margin-left: 0px;
    margin-top: 20px;
  }
  .container {
    padding: 25px;
  }
}

@media (max-width: 650px) {
  .navbar-title {
    display: none;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 15px;
  }
  .footer {
    width: calc(100% - 30px);
  }
}