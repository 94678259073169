@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cdcdcd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

tr.dx-row.dx-header-row>td {
  text-align: center !important;
}

.biker-cell {
  border-radius: 20px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.circle-cell {
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.circle-cell-1 {
  background-color: #679f16;
}

.circle-cell-2 {
  background-color: #64acc5;
}

.circle-cell-3 {
  background-color: black;
  color: white;
}

.circle-cell-4 {
  background-color: red;
}

p.data-grid-text {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.order-notification {
  font-weight: bold;
  color: black;
  border-radius: 20px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-alert-to-be-late {
  background-color: #ffdfba;
}

.order-alert-on-time {
  background-color: #baffc9;
}

.order-alert-verify {
  background-color: #ffffba;
}

.order-alert-late {
  background-color: #ffb3ba;
}

.order-alert-default {
  background-color: #bae1ff;
}

.order-status-new {
  background-color: #5bc0eb;
}

.order-status-going {
  background-color: #fde74c;
}

.order-status-cancelled {
  background-color: #9bc53d;
}

.order-status-delivered {
  background-color: #9bc53d;
}

.expandable-header {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  display: inline-block;
  width: auto;
}

.expandable-header div {
  display: inline-block;
}

.expandable-header .expandable-icon {
  width: 40px;
  vertical-align: middle;
}

.card-container {
  display: inline-block;
  margin: 10px;
}

.card {
  min-width: 200px;
  min-height: 125px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px;
}

.text-center {
  text-align: center;
}

.text-left-imp {
  text-align: left !important;
}

.no-padding {
  padding: 0px;
}

.padding-top-sm {
  padding-top: 10px;
}

.padding-bottom-sm {
  padding-bottom: 10px;
}

.margin-top-sm {
  margin-top: 10px;
}

.margin-left-sm {
  margin-left: 10px;
}

.margin-right-sm {
  margin-right: 10px;
}

.margin-right-lg {
  margin-right: 20px;
}

.card-title {
  padding-bottom: 3px;
  margin-bottom: 5px;
  border-bottom: 1px solid #efefef;
}

.container {
  padding: 25px 50px;
}

.page-title {
  text-align: center;
  text-transform: uppercase;
}

.create-order-button-orders {
  text-align: left;
  margin-bottom: 10px;
}

.btn {
  appearance: button;
  -moz-appearance: button;
  -webkit-appearance: button;
  min-height: 35px;
  padding: 7px 15px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  outline: none;
}

.btn-success {
  background: #20419a;
  color: white;
}

.btn-success-hover:hover,
.btn-success-inverted {
  background: white;
  color: #20419a;
  border: 1px solid #20419a;
}

.modal-container {
  height: 325px;
  width: 700px;
  background-color: white;
  margin: auto;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.modal-container-general {
  height: 325px;
  width: 700px;
  background-color: white;
  margin: auto;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.modal-container-create-order-general {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding-bottom: 15px;
}


.modal-title-general {
  /* position: absolute;
  top: 0; */
  border-bottom: 1px solid #efefef;
  padding: 5px;
  width: calc(100% - 30px);
}

.modal-container-observations {
  height: 220px;
}

.modal-container-create-order {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding-bottom: 15px;

}

.modal-container-assign-biker {
  height: 460px;
  z-index: 999;
}

.modal-container-assign-only-biker {
  height: 250px;
  z-index: 999;
}

.modal-container-height {
  height: 500px;
}

.modal-title {
  /* position: absolute;
  top: 0; */
  border-bottom: 1px solid #efefef;
  padding: 15px;
  width: calc(100% - 30px);
}

.modal-title-text {
  display: inline-block;
  width: 95%;
}

.modal-title .close {
  display: inline-block;
  color: gray;
  cursor: pointer;
  vertical-align: top;
}

.modal-body {
  padding: 0px 15px;
  width: calc(100% - 30px);
  margin-top: 15px;
  overflow-y: auto;
}

.modal-body-new {
  max-height: calc(90vh - 60px);
  overflow-y: auto;
  padding: 15px;
}

.modal-footer {
  border-top: 1px solid #efefef;
  padding-top: 10px;
}

.modal-auto-height {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.modal-container-auto-height {
  width: 700px;
  background-color: white;
  margin: auto;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px;
  outline: none;
  position: relative;
}

.align-right {
  text-align: right;
}

.mr-sm {
  margin-right: 10px;
}

.modal-delivery-system {
  top: 50%;
  transform: translate(0, -50%);
  bottom: 0;
  left: 0;
  right: 0;
  outline: none;
  position: absolute;
}

.input-container div.MuiFormControl-root {
  width: 95%;
}

.card-order-alert-to-be-late {
  background-color: #ffdfba;
}

.card-order-alert-on-time {
  background-color: #baffc9;
}

.card-order-alert-verify {
  background-color: #ffffba;
}

.card-order-alert-late {
  background-color: #ffb3ba;
}

.text-field {
  display: inline-block;
  padding: 0px 5px;
  margin-bottom: 15px;
}

.margin-auto {
  margin: auto;
}

.full-width {
  width: 100%;
}

.full-width-child,
.full-width-child * {
  width: 100%;
}

.padding-r-xsm {
  padding-right: 5px;
}

.padding-l-xsm {
  padding-left: 5px;
}

.margin-top-xsm {
  margin-top: 5px;
}

.margin-top-md {
  margin-top: 15px;
}

.margin-right-xsm {
  margin-right: 5px;
}

.margin-right-sm {
  margin-right: 10px;
}

.margin-right-md {
  margin-right: 15px;
}

.margin-right-xlg {
  margin-right: 25px;
}

.margin-bottom-sm {
  margin-bottom: 10px;
}

.margin-bottom-md {
  margin-bottom: 15px;
}

.margin-bottom-lg {
  margin-bottom: 20px;
}

.margin-bottom-xsm {
  margin-bottom: 5px;
}

.margin-bottom-none {
  margin-bottom: 0px;
}

.rotate-icon {
  display: inline-block;
  padding: 0px 5px;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
}

.btn-alignment-left {
  text-align: left;
}

.btn-alignment-center {
  text-align: center;
}

.btn-alignment-right {
  text-align: right;
}

.display-inline-block {
  display: inline-block;
}

.progress-indicator {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}

.comanda-img {
  text-align: center;
}

/* .comanda-img img {
  width: 90%;
} */

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.font-size-sm {
  font-size: 10px;
}

.col-1 {
  display: inline-block;
  width: calc(8.33333333333% - 10px);
}

.col-2 {
  display: inline-block;
  width: calc(16.666666666666666% - 10px);
}

.col-3 {
  display: inline-block;
  width: calc(25% - 10px);
}

.col-4 {
  display: inline-block;
  width: calc(33.33333333333% - 10px);
}

.col-5 {
  display: inline-block;
  width: calc(41.666666666666666666666666% - 10px);
}

.col-6 {
  display: inline-block;
  width: calc(50% - 10px);
}

.col-7 {
  display: inline-block;
  width: calc(58.3333333333333333% - 10px);
}

.col-8 {
  display: inline-block;
  width: calc(66.666666666666666% - 10px);
}

.col-9 {
  display: inline-block;
  width: calc(75% - 10px);
}

.col-10 {
  display: inline-block;
  width: calc(83.3333333333333333% - 10px);
}

.col-11 {
  display: inline-block;
  width: calc(91.6666666666666% - 10px);
}

.col-12 {
  display: inline-block;
  width: calc(100% - 10px);
}

.scrollable-column {
  max-height: 775px;
  overflow-y: auto;
}

.scrollable-column-img {
  max-height: 750px;
  overflow-y: auto;
}

.pilot-data-display {
  padding-bottom: 5px;
  margin-right: 15px;
  border-bottom: 1px solid #d8d8d8;
}

.overflow-auto {
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.rotation-wrapper-outer {
  display: table;
}

.rotation-wrapper-inner {
  padding: 50% 0;
  height: 0;
}

.element-to-rotate {
  display: block;
  transform-origin: top left;
  margin-top: -50%;
  white-space: nowrap;
}

.translate-Y-down {
  transform: rotate(-90deg) translate(-100%);
}

.translate-Y-top {
  transform: rotate(90deg) translate(0, -100%);
}

.translate-X-reverse {
  transform: rotate(180deg) translate(-100%, -100%);
}

.comanda-img>.MuiPaper-root {
  background: white;
  color: black;
}

.page-container,
.relative-pos {
  position: relative;
}

.container-body {
  margin-bottom: 3rem;
  overflow: auto;
}

.content {
  min-height: 100%;
}

.footer {
  position: relative;
  bottom: 0;
  height: 3rem;
}

.table-footer {
  position: fixed;
  background-color: #f1f0f0;
  border-top: 1px solid #808080;
  color: #808080;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding-bottom: 10px;
  padding-left: 300px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
  background-color: #f1f0f0;
  border-top: 1px solid #808080;
  color: #808080;
  width: 100%;
  height: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.floating-action-button {
  position: fixed;
  left: 30px;
  bottom: 15px;
}

.position-relative,
.dx-editor-with-menu {
  position: relative;
}

/* .dx-editor-container {
  position: absolute;
  top: 0;
} */

.dx-editor-container input {
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

.dx-menu-items-container {
  padding-left: 0px;
}

.pagination-filters {
  width: 100%;
  margin-top: 15px;
}

.dx-placeholder {
  display: none;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-top {
  vertical-align: top;
}

.text-field-errors {
  color: red;
}

.orders-resume-container {
  max-height: 320px;
  overflow: auto;
}

.orders-resume-with-map {
  padding-left: 15px;
}

.orders-resume-title {
  padding: 10px;
  text-align: center;
}

.custom-errors {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
}

.company-branches-links {
  text-align: left;
}

.company-branches-links>div {
  display: inline-block;
  font-size: 14px;
  color: #7575ff;
  cursor: pointer;
}

.biker-link {
  text-align: center;
}

.biker-link>div {
  display: inline-block;
  margin-right: 35px;
  color: #7575ff;
  cursor: pointer;
}

.biker-link>div:hover {
  text-decoration: underline;
}

.company-branches-links>div:hover {
  text-decoration: underline;
}

.branch-view .MuiTableCell-root {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.branch-resume-container {
  text-align: right;
  margin-bottom: 10px;
}

.branch-resume-container>button {
  margin-right: 10px;
}

.branch-resume-container>button:last-child {
  margin-right: 0px;
}

.map-filter-row {
  padding-bottom: 10px;
}

.map-filters-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.map-filters-container {
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.map-filter-row button {
  margin-right: 15px;
}

.map-filter-row button:last-child {
  margin-right: 0px;
}

.MuiAutocomplete-listbox,
.text-capitalize input {
  text-transform: uppercase !important;
}

.card-container-half.card-container {
  width: calc(50% - 20px);
}

.card-container-4.card-container {
  width: calc(25% - 20px);
}

.card-container-5.card-container {
  width: calc(20% - 20px);
}

.map-left-filters {
  display: inline-block;
  width: 50px;
}

.map-left-container {
  display: inline-block;
  width: calc(100% - 50px);
}

.map-left-filters-expanded {
  display: inline-block;
  width: 250px;
}

.map-left-filters-expanded button {
  display: block;
  margin-bottom: 12.5px;
  width: calc(100% - 15px);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.map-left-container-expanded {
  display: inline-block;
  width: calc(100% - 250px);
}

.flexbox-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-border-bottom .card-title {
  border-bottom: none;
}

.order-alerts-img {
  width: 100px;
  height: 100px;
  float: left;
  margin-left: 25px;
  margin-bottom: 15px;
  border-radius: 100%;
  background: #e6e6e6;
}

.order-alerts-img img {
  margin-top: 9px;
  width: 75%;
}

.order-alerts-text {
  float: right;
  margin-right: 75px;
}

.canvasjs-chart-credit {
  display: none;
}

.canvas-js-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 61px;
  height: 50px;
  background: white;
  z-index: 999;
}

.branch-view .card-container {
  margin-left: 0px;
}

.branch-resume-cards {
  /* background: #ebebeb; */
  background: black;
  color: white;
  border-radius: 16px;
  height: 32px;
  align-items: center;
  display: inline-flex;
}

.branch-resume-cards .branch-resume-card-text {
  padding-left: 12px;
  padding-right: 12px;
}

.chip-avatar>.MuiAvatar-root {
  /* color: #616161; */
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  margin-left: 5px;
  margin-right: -6px;
}

.display-inline-flex {
  display: inline-flex;
}

.hover-pointer:hover {
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.padding-lg {
  padding: 20px;
}

.dx-scrollable-container {
  overflow-x: auto;
}

.reduced-padding .dx-scrollable-container .dx-datagrid-content .dx-row>td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.two-wheeler-pic img {
  width: 200px;
}

.branch-alerts {
  position: absolute;
  top: 55px;
  left: 10px;
  background: white;
  z-index: 998;
  width: 300px;
  overflow-y: auto;
}

.all-alerts {
  position: absolute;
  background: white;
  bottom: 15px;
  left: 140px;
  z-index: 999;
  width: calc(100% - 300px);
  height: 150px;
}

.twowheelers-alerts {
  position: absolute;
  background: white;
  top: 55px;
  right: 15px;
  z-index: 998;
  width: 300px;
  overflow-y: auto;
}

.branch-alert-title {
  padding: 5px 10px;
  align-items: center;
  display: flex;
}

.branch-alert-img {
  display: inline-block;
}

.branch-alert-text {
  display: inline-block;
  margin-left: 15px;
}

.branch-alert-img>img {
  width: 40px;
  height: 40px;
}

.branch-restaurant {
  background: #f26724;
  color: white;
}

.branch-2wheeler {
  background: #20419a;
  color: white;
}

.branch-alerts-item {
  padding: 10px 7.5px;
  border-bottom: 1px solid #eeeeee;
}

.branch-alerts-item-title {
  display: inline-block;
  width: calc(100% - 60px);
}

.branch-alerts-item-img {
  position: relative;
  display: inline-block;
  width: 60px;
  vertical-align: top;
}

.branch-alerts-item-img img {
  max-width: 75%;
}

.branch-alerts-item-img span {
  position: absolute;
  top: 0;
  right: 0px;
  font-weight: bold;
}

.all-alerts-item {
  width: 20%;
  height: 150px;
  display: inline-block;
}

.branch-alerts-bottom-img {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background: #e6e6e6;
  left: 15px;
  top: 40px;
}

.branch-alerts-bottom-img img {
  margin-left: 10px;
  margin-top: 9px;
  width: 75%;
}

.branch-alerts-bottom-text {
  position: absolute;
  right: 30%;
  top: 40px;
}

.available-bikers {
  background: #82be4c;
}

.orders-to-attend,
.average-delivery-time {
  background: #e1e945;
}

.orders-to-be-late {
  background: #6162d6;
}

.incidents {
  background: #e27482;
}

.branch-alerts-bottom-title {
  color: white;
  position: absolute;
  top: 7px;
  text-align: center;
  width: 100%;
}

.branch-alerts-bottom-title.black {
  color: black;
}

.all-alerts-container {
  min-width: 200px;
  max-width: 322px;
  height: 150px;
  margin: auto;
}

.orders-to-be-late-img img {
  margin-top: 11px;
  margin-left: 7px;
}

.right-25 {
  right: 25%;
}

.right-5 {
  right: 5%;
}

.all-upper-case,
.all-upper-case * {
  text-transform: uppercase !important;
}

.edit-form-arrow {
  background: #ccc;
  border-radius: 45px;
  width: 35px;
  text-align: center;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.link {
  color: #7575ff;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.company-resume-container img {
  height: 150px;
  max-width: 100%;
}

.company-resume-container {
  text-align: center;
  border: 1px solid #ccc;
  height: 219px;
  vertical-align: top;
}

.company-resume-text {
  line-height: 219px;
  padding: 0px;
  margin: 0px;
}

.company-branch-logo {
  text-align: center;
}

.company-branch-logo img {
  max-width: 80%;
}

.m-top-xxlg {
  margin-top: 34px;
}

.biker-list,
.biker-list * {
  font-size: 14px !important;
}

.resume-pie-chart-labels {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: justify;
}

.resume-pie-chart-box-color {
  display: inline-block;
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.resume-pie-chart-box-text {
  display: inline-block;
  line-height: 20px;
  vertical-align: top;
}

.alert-abandoned-orders {
  position: absolute;
  left: 330px;
  top: 25px;
  padding: 15px 25px;
  z-index: 999;
  background: orange;
  color: white;
  text-align: center;
}

.red-background-light {
  background-color: rgba(255, 0, 0, 0.3);
}

.orange-background-light {
  background-color: rgba(255, 165, 0, 0.3);
}

.no-margin-imp {
  margin: 0px !important;
}


/* image pic  */

.imgPicTable {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 25px;
  border: 1px solid lightgray;
  background-color: #fff;
}


.task-info {
  font-family: Segoe UI;
  min-height: 200px;
  display: flex;
  flex-wrap: nowrap;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  box-sizing: border-box;
  align-items: baseline;
  justify-content: space-between;
}

#taskSubject {
  line-height: 29px;
  font-size: 18px;
  font-weight: bold;
}

#taskDetails {
  line-height: 22px;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.progress {
  display: flex;
  flex-direction: column;
  white-space: pre;
  min-width: 95px;
}

.secondsinformation {
  display: flex;
  flex-direction: column;
  white-space: pre;
  min-width: 50px;
}

.info {
  margin-right: 40px;
}

#taskProgress {
  line-height: 42px;
  font-size: 25px;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  text-align: center;
}

.options {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: relative;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  margin-right: 40px;
  display: inline-block;
}

.option:last-child {
  margin-right: 0;
}

.option>.dx-numberbox {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.option>span {
  margin-right: 10px;
}

.dx-data-row .cell-highlighted {
  background-color: #0000;
  font-size: 13px;
  font-style: 'Open Sans', sans-serif;
}

.dx-header-row .cell-highlighted {
  color: gray;
  background-color: #ebf5fb;
  font-size: 14px;
  font-style: 'Open Sans', sans-serif;
}

.dx-field-label {
  text-transform: uppercase;
}

.cell-highlightedOnCompany {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

/* NUEVO CSS PARA CREAR ORDENES DESDE LAS BRANCH COMPANYS */

.modal-container-create-order-branch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

/* Contenedor principal del modal */
.modal-content-create-order-branch {
  background-color: white;
  width: 90%;
  max-width: 1200px;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  max-height: 90vh;
}

/* Contenedor del cuerpo del modal con dos columnas */
.modal-body-create-order-branch {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  gap: 0;
  height: calc(100% - 60px);
  overflow: hidden;
}

/* Contenedor del mapa */
.map-container-create-order-branch {
  padding: 0;
  height: 100%;
  overflow: hidden;
}

/* Contenedor interno del mapa */
.map-container-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

/* Contenedor del formulario */
.form-container-create-order-branch {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

/* Título del modal */
.modal-title-create-order-branch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

/* Icono de creación */
.modal-icon-create-order-branch {
  width: 40px;
  height: auto;
}

/* Botón de cerrar */
.close-button-create-order-branch {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}
@media (max-width: 1050px) {
  .branch-resume-col {
    width: calc(100% - 10px);
    display: block;
  }
  .modal-container {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: calc(100% - 10px);
    display: block;
  }
  .branch-resume-table-view {
    margin-left: 0px;
    margin-top: 20px;
  }
  .container {
    padding: 25px;
  }
}

@media (max-width: 650px) {
  .navbar-title {
    display: none;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 15px;
  }
  .footer {
    width: calc(100% - 30px);
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
};

* {
  font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track{
  /* border: 7px solid #dcdcdc; */
  /* box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5); */
}
::-webkit-scrollbar-thumb{
  background-color: #808080;
  border-radius: 10px;
}

.branch-day-title {
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.contenedor{
    width: 321;
    height: 214;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media (max-width: 767px){
    .video{
          width: 70vh;
          height: 70vh;
      }
}

.header-content{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(71, 75, 78, 0.3);
}



.contenedorDelivery{
    width: 600;
    height: auto;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

/* 

.header-video{
  position: fixed;   
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;

}




.header-content{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(71, 75, 78, 0.3);
}
.video-opacity{
  opacity: 0.6;
} */

.header-video{
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 0%;
  right: 0;
  bottom: 0;
  border: none;
  width: 100%;
  z-index: -1;
  height: 100%;
  object-fit: cover;
}

.header-video-content{
  content:"";
  position:absolute;
  top:0;
  left:0; 
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.6);
}
.content {
    display: flex;
    flex-wrap: wrap;
}

.left-column {
    box-sizing: border-box;
    flex-basis: 50%;
    padding: 10px;
}

.right-column {
    box-sizing: border-box;
    flex-basis: 50%;
    padding: 10px;
}

.map-container {
    flex-basis: 100%;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.information-biker {
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    flex-basis: 100%;
    padding: 10px;
}

.tracking-delivery {
    flex-basis: 100%;
    /* border: 1px solid #ccc;
    border-radius: 8px; */
    padding: 10px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    position: relative;
    /* Añadido para posición relativa */
}

.show-more-button {
    display: block;
    width: 98%;
    /* El botón ocupa todo el ancho del div */
    margin-top: 10px;
    padding: 5px;
    /* Añadido padding para ajustar el tamaño */
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
}

@media (max-width: 768px) {

    .left-column,
    .right-column {
        flex-basis: 100%;
    }
}

@media (min-width: 768px) {
    .content {
        flex-wrap: nowrap;
        /* Vuelve a una sola fila en pantallas grandes */
    }

    .map-container {
        flex-basis: 40%;
    }

    .right-column {
        flex-basis: 60%;
    }

    .tracking-delivery {
        max-height: none;
        overflow: visible;
        display: block;
    }
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .25;
        transform: scale(.75);
    }
}

/* GRID STYLING */

* {
    box-sizing: unset;
}

.spinner-box-custom {
    background-color: transparent;
}

.pulse-container-custom {
    display: flex;
}

.pulse-bubble-custom {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #f26724;
}

.pulse-bubble-1-custom {
    animation: pulse .4s ease 0s infinite alternate;
}

.pulse-bubble-2-custom {
    animation: pulse .4s ease .2s infinite alternate;
}

.pulse-bubble-3-custom {
    animation: pulse .4s ease .4s infinite alternate;
}

.bodyImage {
    height: 100hv;
    margin: 0;
}

.bg {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 17px;
  font-family: monospace;
  line-height: 1.4;
  font-weight: bold;
  background: 
    linear-gradient(#000 0 0) left ,
    linear-gradient(#000 0 0) right;
  background-repeat: no-repeat; 
  border-right: 5px solid #0000;
  border-left: 5px solid #0000;
  background-origin: border-box;
  position: relative;
  animation: l9-0 2s infinite;
}
.loader::before {
  content:"Cargando";
}
.loader::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 22px;
  height: 60px;
  background: 
   linear-gradient(90deg,#000 4px,#0000 0 calc(100% - 4px),#000 0) bottom            /22px 20px,
   linear-gradient(90deg,red  4px,#0000 0 calc(100% - 4px),red  0) bottom 10px left 0/22px 6px,
   linear-gradient(#000 0 0) bottom 3px left 0  /22px 8px,
   linear-gradient(#000 0 0) bottom 0   left 50%/8px  16px;
 background-repeat: no-repeat;
 animation: l9-1 2s infinite;
}
@keyframes l9-0{
  0%,25%    {background-size: 50% 100%}
  25.1%,75% {background-size: 0 0,50% 100%}
  75.1%,100%{background-size: 0 0,0 0}
}
@keyframes l9-1{
  25%   { background-position:bottom, bottom 54px left 0,bottom 3px left 0,bottom 0 left 50%;left:0}
  25.1% { background-position:bottom, bottom 10px left 0,bottom 3px left 0,bottom 0 left 50%;left:0}
  50%   { background-position:bottom, bottom 10px left 0,bottom 3px left 0,bottom 0 left 50%;left:calc(100% - 22px)}
  75%   { background-position:bottom, bottom 54px left 0,bottom 3px left 0,bottom 0 left 50%;left:calc(100% - 22px)}
  75.1% { background-position:bottom, bottom 10px left 0,bottom 3px left 0,bottom 0 left 50%;left:calc(100% - 22px)}
}

.contenedor{
    width: 321;
    height: 214;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media (max-width: 767px){
    .video{
          width: 70vh;
          height: 70vh;
      }
}

.header-content{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(71, 75, 78, 0.3);
}



.contenedorDelivery{
    width: 600;
    height: auto;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

#d-wrapper {
background-color: #FBFCFC ;
}
#d-wrapper * {
	margin:0;
	padding:0;
}

#d-wrapper	.zig-zag-top:before{
		background: 
				linear-gradient(-45deg,#F2F2F2 16px,  transparent 0), 
				linear-gradient(45deg,#F2F2F2  16px,transparent 0);
        background-position: left top;
        background-repeat: repeat-x;
        background-size: 22px 32px;
        content: " ";
        display: block;
        height: 32px;
		width: 100%;
		position: relative;
		bottom: 35px;
		left:0;
	}

#d-wrapper	div > * {
		margin: 0 3px;
        margin-bottom: 4px;
		margin-top: 1px;
	}

#d-wrapper	.zig-zag-bottom{
	margin: 55px 0;
	margin-top: 0;
	background: #F2F2F2;
}

#d-wrapper	.zig-zag-top{
		margin: 50px 0;
		margin-bottom: 0;
		background: #F2F2F2;
	}

#d-wrapper	.zig-zag-bottom,
#d-wrapper	.zig-zag-top{
			  padding: 5px 0;
	}


#d-wrapper	.zig-zag-bottom:after{
	background: 
		linear-gradient(-45deg, transparent 16px, #F2F2F2 0), 
		linear-gradient(45deg, transparent 16px, #F2F2F2  0);
	background-repeat: repeat-x;
	background-position: left bottom;
	background-size: 22px 32px;
	content: "";
	display: block;

	width: 100%;
	height: 10px;

	position: relative;
	top: 14px;
	left:0px;
}


@keyframes pulse {
	from {
	  opacity: 1;
	  transform: scale(1);
	}
	to {
	  opacity: .25;
	  transform: scale(.75);
	}
  }
  
  /* GRID STYLING */
  
  * {
	box-sizing:unset;
  }
  
  .spinner-box {
	background-color: transparent;
  }
  .pulse-container {
	display:  flex;
}
  
  .pulse-bubble {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #f26724;
  }
  
  .pulse-bubble-1 {
	  animation: pulse .4s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
	  animation: pulse .4s ease .2s infinite alternate;
  }
  .pulse-bubble-3 {
	  animation: pulse .4s ease .4s infinite alternate;
  }
  
  .bodyImage{
	height: 100hv;
	margin: 0;
  }
  
  .bg {
	height: 100%; 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
  }
  
  
.battery-icon {
    width: 50px;
    height: 25px;
    background-color: #333;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 2px;
}

.battery-body {
    width: 80%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    position: relative;
}

.battery-level {
    width: 50%;
    height: 100%;
    background-color: #4caf50;
    /* Color verde para la carga de la batería */
    border-radius: 5px;
}
table {
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-weight: 100;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    background-color: #FBFCFC;
}

th,
td {
    padding: 15px;
    background-color: #ffffff33;
    color: black;
}

th {
    text-align: left;
}

thead th {
    background-color: #20419a;
    color: #fff;
}

tbody tr:hover {
    background-color: #00000033;
}

tbody td {
    position: relative;
}

tbody td:hover:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -9999px;
    bottom: -9999px;
    background-color: #00000033;
    z-index: -1;
}
