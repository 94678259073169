
.contenedor{
    width: 321;
    height: 214;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media (max-width: 767px){
    .video{
          width: 70vh;
          height: 70vh;
      }
}

.header-content{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(71, 75, 78, 0.3);
}



.contenedorDelivery{
    width: 600;
    height: auto;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
