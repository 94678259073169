#d-wrapper {
background-color: #FBFCFC ;
}
#d-wrapper * {
	margin:0;
	padding:0;
}

#d-wrapper	.zig-zag-top:before{
		background: 
				linear-gradient(-45deg,#F2F2F2 16px,  transparent 0), 
				linear-gradient(45deg,#F2F2F2  16px,transparent 0);
        background-position: left top;
        background-repeat: repeat-x;
        background-size: 22px 32px;
        content: " ";
        display: block;
        height: 32px;
		width: 100%;
		position: relative;
		bottom: 35px;
		left:0;
	}

#d-wrapper	div > * {
		margin: 0 3px;
        margin-bottom: 4px;
		margin-top: 1px;
	}

#d-wrapper	.zig-zag-bottom{
	margin: 55px 0;
	margin-top: 0;
	background: #F2F2F2;
}

#d-wrapper	.zig-zag-top{
		margin: 50px 0;
		margin-bottom: 0;
		background: #F2F2F2;
	}

#d-wrapper	.zig-zag-bottom,
#d-wrapper	.zig-zag-top{
			  padding: 5px 0;
	}


#d-wrapper	.zig-zag-bottom:after{
	background: 
		linear-gradient(-45deg, transparent 16px, #F2F2F2 0), 
		linear-gradient(45deg, transparent 16px, #F2F2F2  0);
	background-repeat: repeat-x;
	background-position: left bottom;
	background-size: 22px 32px;
	content: "";
	display: block;

	width: 100%;
	height: 10px;

	position: relative;
	top: 14px;
	left:0px;
}


@keyframes pulse {
	from {
	  opacity: 1;
	  transform: scale(1);
	}
	to {
	  opacity: .25;
	  transform: scale(.75);
	}
  }
  
  /* GRID STYLING */
  
  * {
	box-sizing:unset;
  }
  
  .spinner-box {
	background-color: transparent;
  }
  .pulse-container {
	display:  flex;
}
  
  .pulse-bubble {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #f26724;
  }
  
  .pulse-bubble-1 {
	  animation: pulse .4s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
	  animation: pulse .4s ease .2s infinite alternate;
  }
  .pulse-bubble-3 {
	  animation: pulse .4s ease .4s infinite alternate;
  }
  
  .bodyImage{
	height: 100hv;
	margin: 0;
  }
  
  .bg {
	height: 100%; 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
  }
  
  